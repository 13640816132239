import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      hash: true,
      mode: 'history',
      children: [
        {
          path: "/schools",
          name: "schools",
          component: () => import("@/views/pages/edu/schools/Schools.vue")
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue")
        },
        {
          path: "/teachers/:id",
          name: "teachers",
          component: () => import("@/views/pages/edu/schools/Teachers.vue")
        },
        {
          path: "/studentGroups/:id",
          name: "studentGroups",
          component: () => import("@/views/pages/edu/schools/StudentGroups.vue")
        },
        {
          path: "/students/:id",
          name: "students",
          component: () => import("@/views/pages/edu/schools/Students.vue")
        },
        {
          path: "/groupSubjects/:id",
          name: "groupSubjects",
          component: () => import("@/views/pages/edu/schools/GroupSubjects.vue")
        },
        {
          path: "/timeTable/:id",
          name: "timeTable",
          component: () => import("@/views/pages/edu/schools/TimeTable.vue")
        },
        {
          path: "/marks/:id",
          name: "marks",
          component: () => import("@/views/pages/edu/schools/Marks/Marks.vue")
        },
        {
          path: "/student/dashboard",
          name: "studentDashboard",
          component: () => import("@/views/pages/edu/student/Dashboard.vue")
        },
        {
          path: "/teacher/dashboard",
          name: "teacherDashboard",
          component: () => import("@/views/pages/edu/teacher/Dashboard.vue")
        },
        {
          path: "/schoolAdmin/dashboard",
          name: "schoolAdminDashboard",
          component: () => import("@/views/pages/edu/schoolAdmin/Dashboard.vue")
        },
        {
          path: "/schoolAdmin/teachers",
          name: "schoolAdminTeachers",
          component: () => import("@/views/pages/edu/schoolAdmin/Teachers.vue")
        },
        {
          path: "/schoolAdmin/studentGroups",
          name: "schoolAdminStudentGroups",
          component: () => import("@/views/pages/edu/schoolAdmin/StudentGroups.vue")
        },
        {
          path: "/groupSubjectsSubGroup/:id/:groupId",
          name: "groupSubjectsSubGroup",
          component: () => import("@/views/pages/edu/schools/GroupSubjectsSubGroup.vue")
        },
        {
          path: "/my/school",
          name: "mySchool",
          component: () => import("@/views/pages/my/MySchool.vue")
        },
        {
          path: "/my/group",
          name: "myGroup",
          component: () => import("@/views/pages/my/MyGroup.vue")
        },
        {
          path: "/teacher/eduPlan",
          name: "teacherEduPlan",
          component: () => import("@/views/pages/edu/educationPlan/List.vue")
        },
        {
          path: "/teacher/eduPlanDetail/:id",
          name: "teacherEduPlanDetail",
          component: () => import("@/views/pages/edu/educationPlan/Info.vue")
        },
        {
          path: "/teacher/classroomTeacherStudentGroups",
          name: "classroomTeacherStudentGroups",
          component: () => import("@/views/pages/edu/teacher/MyGroups.vue")
        },  
        {
          path: "/teacher/subjects",
          name: "teacherSubjects",
          component: () => import("@/views/pages/edu/teacher/GroupSubjects.vue")
        },
        {
          path: "/school/timeTable",
          name: "timeTableForSchool",
          component: () => import("@/views/pages/edu/timeTable/TimeTable.vue")
        },
        {
          path: "/school/timeTable/group/:id",
          name: "timeTableForGroup",
          component: () => import("@/views/pages/edu/timeTable/TimeTableForGroup.vue")
        }, 
        {
          path: "/school/group/grade/book/:groupId",
          name: "groupGradeBook",
          component: () => import("@/views/pages/edu/gradeBook/GroupGradeBook.vue")
        },
        {
          path: "/school/group/quarter/mark/:groupId/:quarter",
          name: "quarterMarks",
          component: () => import("@/views/pages/edu/quarterMarks/QuarterMarks.vue")
        },
        {
          path: "/student/quarter/marks",
          name: "studentQuarterMarks",
          component: () => import("@/views/pages/edu/student/QuarterMarks.vue")
        },
        {
          path: "/school/pages",
          name: "schoolPages",
          component: () => import("@/views/pages/edu/schoolAdmin/SchoolPages.vue")
        },
        {
          path: "/school/certificates",
          name: "certificates",
          component: () => import("@/views/pages/edu/schoolAdmin/Certificates.vue")
        },
        {
          path: "/lesson/marks/:lessonId",
          name: "lessonMarks",
          component: () => import("@/views/pages/edu/schools/LessonMarks.vue")
        },
        {
          path: "/studentInfo/:id",
          name: "studentInfo",
          component: () => import("@/views/pages/edu/schools/StudentInfo.vue")
        },
        {
          path: "/import/student",
          name: "importStudent",
          component: () => import("@/views/pages/edu/schoolAdmin/ImportStudent.vue")
        },
        {
          path: "/import/teacher",
          name: "importTeacher",
          component: () => import("@/views/pages/edu/schoolAdmin/ImportTeacher.vue")
        },
        {
          path: "/studentGroup/details/:groupId",
          name: "groupInfo",
          component: () => import("@/views/pages/edu/schools/group/GroupInfo.vue")
        },   
        {
          path: "/user/info",
          name: "userInfo",
          component: () => import("@/views/pages/edu/user/ChangePassword.vue")
        }, 
        {
          path: "/journals",
          name: "journals",
          component: () => import("@/views/pages/edu/schools/subjects/GroupSubjects.vue")
        }, 
        {
          path: "/schoolStudents",
          name: "schoolStudents",
          component: () => import("@/views/pages/edu/schools/students/SchoolStudents.vue")
        },
        {
          path: "/teacherInfo/:id",
          name: "teacherInfo",
          component: () => import("@/views/pages/edu/schools/teacher/TeacherInfo.vue")
        },   
        {
          path: "/marksForSchool",
          name: "marksForSchool",
          component: () => import("@/views/pages/edu/schools/Marks/MarksGeneral.vue")
        },   
        {
          path: "/schoolProgram",
          name: "schoolProgram",
          component: () => import("@/views/pages/edu/schools/groupSubjects/SchoolProgram.vue")
        },   
        {
          path: "/quarterMarksForSubject/:groupSubjectId/:quarter",
          name: "quarterMarksForSubject",
          component: () => import("@/views/pages/edu/quarterMarks/QuarterMarksForSubject.vue")
        },
        {
          path: "/school/absenceLogReport",
          name: "absenceLogReport",
          component: () => import("@/views/pages/edu/schools/reports/AbsenceLogReport.vue")
        },
        /*education department*/
        {
          path: "/educationDepartment/schools",
          name: "educationDepartmentSchools",
          component: () => import("@/views/pages/management/educationDepartment/Schools.vue")
        },
        {
          path: "/educationDepartment/teachers",
          name: "educationDepartmentTeachers",
          component: () => import("@/views/pages/management/educationDepartment/Teachers.vue")
        },
        {
          path: "/educationDepartment/students",
          name: "educationDepartmentStudents",
          component: () => import("@/views/pages/management/educationDepartment/Students.vue")
        },
        {
          path: "/educationDepartment/absenceLogReport",
          name: "educationDepartmentAbsenceLogReport",
          component: () => import("@/views/pages/management/educationDepartment/reports/AbsenceLogReport.vue")
        },
        {
          path: "/educationDepartment/reports/educationProgressReport",
          name: "educationDepartmentEducationProgressReport",
          component: () => import("@/views/pages/management/educationDepartment/reports/EducationProgressReport.vue")
        },
        {
          path: "/educationDepartment/preschoolAbsenceLogReport",
          name: "educationDepartmentPreschoolAbsenceLogReport",
          component: () => import("@/views/pages/management/educationDepartment/reports/PreschoolAbsenceLogReport.vue")
        },
        /*reports*/
        {
          path: "/school/reports/educationProgressReport",
          name: "schoolEducationProgressReport",
          component: () => import("@/views/pages/edu/schools/reports/EducationProgressReport.vue")
        },
        {
          path: "/school/reports/educationProgressReportBySubject",
          name: "EducationProgressReportBySubject",
          component: () => import("@/views/pages/edu/schools/reports/EducationProgressReportBySubject.vue")
        },
        {
          path: "/school/reports/educationProgressReportByTeacher",
          name: "EducationProgressReportByTeacher",
          component: () => import("@/views/pages/edu/schools/reports/EducationProgressReportByTeacher.vue")
        },   
        {
          path: "/school/reports/educationProgressReportForTeacher",
          name: "EducationProgressReportForTeacher",
          component: () => import("@/views/pages/edu/schools/reports/EducationProgressReportForTeacher.vue")
        },
        {
          path: "/school/reports/schoolSocialPassportReport",
          name: "SchoolSocialPassportReport",
          component: () => import("@/views/pages/edu/schools/reports/SchoolSocialPassportReport.vue")
        }, 
        {
          path: "/school/reports/summaryReportOfStudentsProgressReport",
          name: "SummaryReportOfStudentsProgressReport",
          component: () => import("@/views/pages/edu/schools/reports/SummaryReportOfStudentsProgressReport.vue")
        }, 
        {
          path: "/school/reports/finalMarksCountReport",
          name: "FinalMarksCountReport",
          component: () => import("@/views/pages/edu/schools/reports/FinalMarksCountReport.vue")
        },      
        {
          path: "/school/reports/informationAboutStudentMovementReport",
          name: "InformationAboutStudentMovementReport",
          component: () => import("@/views/pages/edu/schools/reports/InformationAboutStudentMovementReport.vue")
        },   
        {
          path: "/school/reports/informationAboutStudentLeavingReport",
          name: "InformationAboutStudentLeavingReport",
          component: () => import("@/views/pages/edu/schools/reports/InformationAboutStudentLeavingReport.vue")
        },
        {
          path: "/school/reports/studentAbsenceNumberOfDaysReport",
          name: "StudentAbsenceNumberOfDaysReport",
          component: () => import("@/views/pages/edu/schools/reports/StudentAbsenceNumberOfDaysReport.vue")
        },
        /*nurse*/
        {
          path: "/school/journal/informationAboutHeathPage",
          name: "InformationAboutHeathPage",
          component: () => import("@/views/pages/edu/schools/journal/InformationAboutHeathPage.vue")
        },
        /*preschool*/
        {
          path: "/preschool/admin/list",
          name: "PreschoolAdmin",
          component: () => import("@/views/pages/edu/preschool/admin/Preschool.vue")
        },
        {
          path: "/preschool/admin/kindergartner/list/:id",
          name: "Kindergartener",
          component: () => import("@/views/pages/edu/preschool/admin/Kindergartener.vue")
        },    
        {
          path: "/preschool/admin/groups/list/:id",
          name: "PreschoolGroups",
          component: () => import("@/views/pages/edu/preschool/admin/PreschoolGroups.vue")
        },        
        {
          path: "/preschool/preschoolAdmin/dashboard",
          name: "PreschoolAdminDashboard",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/Dashboard.vue")
        },    
        {
          path: "/preschool/preschoolAdmin/kindergartners",
          name: "PreschoolAdminKindergartners",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/Kindergartener.vue")
        }, 
        {
          path: "/preschool/preschoolAdmin/groups",
          name: "PreschoolAdminGroups",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/PreschoolGroups.vue")
        }, 
        {
          path: "/preschool/preschoolAdmin/students/:id",
          name: "PreschoolAdminStudents",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/Students.vue")
        },
        {
          path: "/preschool/preschoolAdmin/studentInfo/:id",
          name: "PreschoolStudentInfo",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/StudentInfo.vue")
        },   
        {
          path: "/preschool/preschoolAdmin/importKindergartener",
          name: "ImportKindergartener",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/ImportKindergartener.vue")
        }, 
        {
          path: "/preschool/preschoolAdmin/importStudents",
          name: "ImportPreschoolStudent",
          component: () => import("@/views/pages/edu/preschool/preschoolAdmin/ImportPreschoolStudent.vue")
        },       
        {
          path: "/preschool/kindergartener/dashboard",
          name: "KindergartenerDashboard",
          component: () => import("@/views/pages/edu/preschool/kindergartener/Dashboard.vue")
        },
        {
          path: "/preschool/group/info/:id",
          name: "PreschoolGroupInfo",
          component: () => import("@/views/pages/edu/preschool/group/GroupInfo.vue")
        },
        {
          path: "/preschool/report/absenceLogReport",
          name: "preschoolAbsenceLogReport",
          component: () => import("@/views/pages/edu/preschool/report/AbsenceLogReport.vue")
        },
        /*transfer list*/
        {
          path: "/school/transfer/list",
          name: "TransferList",
          component: () => import("@/views/pages/edu/schoolAdmin/TransferList.vue")
        },    
        /*export*/
        {
          path: "/journal/export/:groupId",
          name: "GroupJournalExport",
          component: () => import("@/views/pages/edu/schools/journal/GroupJournalExport.vue")
        },
        /*page admin*/
        {
          path: "/school/pageAdmin/pages",
          name: "schoolPAPages",
          component: () => import("@/views/pages/edu/schoolAdmin/SchoolPages.vue")
        },
        {
          path: "/school/pageAdmin/certificates",
          name: "pACertificates",
          component: () => import("@/views/pages/edu/schoolAdmin/Certificates.vue")
        },
        /*infrastructure*/
        {
          path: "/school/infrastructure/classrooms",
          name: "schoolClassrooms",
          component: () => import("@/views/pages/edu/schools/infrastructure/Classrooms.vue")
        },
        /*school timetable*/
        {
          path: "/school/timeTable/templates",
          name: "schoolTimeTable",
          component: () => import("@/views/pages/edu/schoolTimeTable/SchoolTimeTable.vue")
        },        {
          
          path: "/school/timeTable/details/:id",
          name: "schoolTimeTableDetails",
          component: () => import("@/views/pages/edu/schoolTimeTable/SchoolTimeTableDetails.vue")
        },
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
